import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const MoreButton = ({ link, className }) => {
    return (
        <Link Link to = {link}>
            <button className={className} type="button" >
                Więcej
            </button>
        </Link>
    )
}

export default styled(MoreButton)`
    padding: 0.75rem 2rem;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: var(--mainWhite);
    font-weight: bold;
    background: ${props => props.background};
    border: 1rem solid linear-gradient(rgba(0,254,254,0.5),rgba(0,254,254,0.5));
`
