import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const SecondHero = ({ img, className, children }) => {
    return (
        <section className={className}>
            <BackgroundImage fluid={img}>
                <div className="first-half">
                    <div className="yellow"></div>
                </div>
            </BackgroundImage>
            <div className="second-half">
                {children}
            </div>
        </section>
    )
}

export default styled(SecondHero)`
    @media(min-width: 40rem) {
        background-position: center;
        background-size: cover;
        opacity: 1 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .gatsby-image-wrapper {
            height: 460px;
            display: flex;
            align-self: flex-start;
        }
        .first-half {
            min-width: calc(100vw - 800px);
            height: 100%;
        }
        .second-half {
            height: 100%;
            padding-left: 8rem;
        }
        .yellow {
            background: linear-gradient(rgba(241,196,0,0.9),rgba(241,196,0,0.9));
            width: 100%;
            height: 50%;
            position: relative;
        }
    }
`
