import React from "react"
import styled from 'styled-components'
import Title from "./title"
import MoreButton from "./more_button"

const ModuleInfo = ({ title, excerpt, children, className }) => {
return (
    <section className={className}>
    <div className="page_excerpt">
        <Title
            txt="Czym jest</br><strong>METODA BOWENA?</strong>"
            color="var(--mainWhite)"
        ></Title>
        <p>Technika Bowena to manualna metoda leczenia oparta na wiedzy z dziedziny anatomii i fizjologii. 
            Należy do technik osteopatycznych. Wykorzystuje naturalne, wrodzone zdolności regeneracyjne, które posiada każdy człowiek. 
            Wpływa zarówno na ciało, jak i sferę psychiczną czy emocjonalną. 
            Zwiększa możliwości organizmu i mobilizuje go do uruchomienia odpowiednich mechanizmów naprawczych.</p>
        <MoreButton
            link="czym-jest"
            background="var(--mainYellow)"
        ></MoreButton>
    </div>
    <div>
        {children}
    </div>
    </section>
)}

export default styled(ModuleInfo)`
    display: inline-block;

    .page_excerpt {
        color: var(--mainWhite);
    }
    
    @media(min-width: 40rem) {
        display: flex;
        align-items: center;

        .page_excerpt {
            max-width: 50%;
        }
}
`