import React from "react"
import styled from "styled-components"
import { MdFormatQuote } from "react-icons/md"

const Reviews = ({ reviews, className }) => {
    return (
        <section className={className}>
            <ul>
                {reviews.map(({ node }) => (
                <li>
                    <cite>
                        <h3>{node.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html:  node.content}} />
                    </cite>
                </li>
                ))}
            </ul>
        </section>
    )
}

export default styled(Reviews)`

    padding: 1rem 2rem 1rem 2rem;
    background: rgba(0,254,254,0.5);
    letter-spacing: 1px;

    ul {
        display: inline-block;
        list-style-type: none;
    }

    li {
        margin-top: 1.5rem;
    }

    @media(min-width: 40rem) {
        
        padding: 4rem 8rem 1rem 8rem;
        
        ul {
            display: flex;
        }
        li {
            margin: 0 1.5rem;
            max-width: 33%;
        }
    }
`

