import React from "react"
import styled from 'styled-components'
import Title from "./title"
import MoreButton from "./more_button"

const ModuleBenefits = ({ title, title_color, excerpt, children, className }) => {
return (
    <section className={className}>
    <div className="page_excerpt">
        <Title
            txt="Korzyści jakie daje</br><strong>METODA BOWENA</strong>"
            color={title_color}
        ></Title>
            <p>Jest to bezpieczna, bezinwazyjna metoda terapeutyczna, która oddziałuje na cały organizm. 
                Zadaniem Techniki Bowena jest pobudzenie naturalnych zdolności ludzkiego organizmu do samoleczenia. 
                Poprzez manualne działanie na mięśnie, ścięgna, nerwy, tkankę łączną następuje przywrócenie swobody ruchu płynów ustrojowych oraz tkanek.</p>
        <MoreButton
            link="czym-jest"
            background="var(--navbarColor)"
        ></MoreButton>
    </div>
    <div>
        {children}
    </div>
    </section>
)}

export default styled(ModuleBenefits)`

    .page_excerpt {
        color: var(--mainGrey);
        padding: 0 2rem;
    }

    @media(min-width: 40rem) {
        display: flex;
        align-items: center;
    }
`