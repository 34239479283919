import React from 'react'
import styled from 'styled-components'
import { MdPhoneIphone } from "react-icons/md"
import { IconContext } from "react-icons";

const BigContact = ({ phone, mail, info, className }) => {
    return (
        <div className={className}>
            <IconContext.Provider value={{ color: "#fdfdfd", size: "3em", className: "phone-icon" }}>
                <MdPhoneIphone />
            </IconContext.Provider>
            <div className="phone_wrapper">
                <phone>{phone}</phone>
                <p>{info}</p>
            </div>
        </div>
    )
}

export default styled(BigContact)`
    display: flex;
    align-items: center;
    margin: 0 -3rem 0 -3rem;

    phone {
        color: var(--mainYellow);
        font-size: 2em;
        font-weight: bold;
    }

    .phone_wrapper {
        background: #fdfdfd;
        padding 1rem;
        letter-spacing: var(--mainSpacing);
    }

    @media(min-width: 40rem) {
        margin-left: 2rem;

    }
`