import React from 'react'
import styled from 'styled-components'

const Title = ({ txt, color, className }) => {
    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: txt}} />
    )
}
export default styled(Title)`
    font-size: 1.7rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: {color};
`

