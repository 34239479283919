import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const StyledHero = ({ img, className, children}) => {
    return (
        <BackgroundImage className={className} fluid={img}>
            {children}
        </BackgroundImage>
    )
}

export default styled(StyledHero)`
    padding: 0 8rem;
    min-height: calc(100vh - 250px);
    background: linear-gradient(rgba(0,254,254,0.5),rgba(0,254,254,0.5));
    background-position: center;
    background-size: cover;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
`
