import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ModuleInfo from "../components/module_info"
import ModuleBenefits from "../components/module_benefits"
import StyledHero from "../components/styled_hero"
import BigContact from "../components/big_contact"
import Reviews from "../components/reviews"
import SecondHero from "../components/second_hero"

export default ({ data }) => {
  const reviews = data.allWordpressPost.edges
  return (
    <Layout>
      <SEO title="Terapia metodą Bowena" />
      <StyledHero img={data.heroBcg.childImageSharp.fluid}>
        <ModuleInfo
          title={data.allWordpressPage.edges[0].node.title}
          excerpt={data.allWordpressPage.edges[0].node.excerpt}
          >
            <BigContact
              phone="692-351-304"
              info="Zadzwoń i umów się na wizytę"
              >
              </BigContact>
        </ModuleInfo>
      </StyledHero>
      <SecondHero img={data.secondBcg.childImageSharp.fluid}>
        <ModuleBenefits
          title={data.allWordpressPage.edges[1].node.title}
          title_color="var(--mainGrey)"
          excerpt={data.allWordpressPage.edges[1].node.excerpt}
          >
        </ModuleBenefits>
      </SecondHero>
      <Reviews reviews={reviews}></Reviews>
    </Layout>
  )
}
/*<ul>
      {data.allWordpressPost.edges.map(({ node }) => (
        <Reviews
          title={node.title}
          content={node.content}
        ></Reviews>
      ))}
      </ul>*/
export const pageQuery = graphql`
query {
    allWordpressPage(sort: { fields: [date] }) {
      edges {
        node {
          title
          content
          excerpt
          slug
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          content
        }
      }
    }
    heroBcg: file(relativePath: { eq: "hero_bcg.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    secondBcg: file(relativePath: { eq: "second_bcg.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/* export const postQuery = graphql`
query {
    
}
`
 */